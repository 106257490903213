import React, {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./style.scss";
import TkSvgIcon from "../../particles/TkSvgIcon";
import {Link} from "react-router-dom";
import {tk_route_my_list, tk_route_my_lists,} from "../../../views/TkMyListsView";
import {List} from "../../../models/list";
import {useTkAppErrors, useTkListPro, useTkMedia, useTkUser, useTkWhatsAppBalloon,} from "../../../context/TkContext";
import {ITkProductModel, ITkProductQuantityModel,} from "../../../models/product";
import TkProportionalImage from "../../particles/TkProportionalImage";
import {tk_route_manufacturer} from "../../../views/TkSearchView";
import {isBlank, replaceDiacritics} from "../../../utils/string-utils";
import TkListQuantityOptions from "../TkListQuantityOptions";
import TkSkeleton from "../../particles/TkSkeleton";
import {GraphQLResult} from "../../../models/graphql";
import TkDivider from "../../particles/TkDivider";
import {formatMoney} from "../../../utils/number-utils";
import {useSimpleInput} from "../../../hooks/UseSimpleInput";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-image.svg';

export interface ITkModalList {
  show(product: ITkProductModel): void
  isShow: boolean
  hide(): void
}

const QuantityField: FC<{
  listId?: string;
  productQuantity: ITkProductQuantityModel;
  updateListState(list: List): void;
}> = ({listId, productQuantity, updateListState}) => {
  const {updateList} = useTkUser();
  const {goToPageError} = useTkAppErrors();
  const [isDoing, setDoing] = useState(false);
  const {
    product: {_id, stock},
    quantity,
  } = productQuantity;


  const [manualQuantity, setManualQuantity] = useState(quantity);
  const [showCustomQuantity, setShowCustomQuantity] = useState(
    quantity > stock && quantity != 1
  );

  useEffect(() => setManualQuantity(1), []);

  const doUpdate = useCallback(async (qtd?: number) => {
      if (isBlank(listId) || qtd < 1) return;

      setDoing(true);
      try {
        const {data} = await updateList(listId, _id, qtd, false);
        if (updateListState) updateListState(data);
      } catch (e) {
        goToPageError(e);
      } finally {
        setDoing(false);
      }
    },
    [updateList, updateListState, setDoing]
  );

  const onChangeQuantity = useCallback(async (e: any) => {
      setShowCustomQuantity(e.target.value === "more");
      if (e.target.value !== "more")
        setManualQuantity(parseInt(e.target.value));
    },
    [doUpdate, setShowCustomQuantity, setManualQuantity]
  );

  return <div className="d-flex flex-d-c a-i-c j-c-c">
    <label htmlFor="modalListProQuantity">Informe a quantidade</label>
    {showCustomQuantity ? (
      <input
        id="modalListProQuantity"
        type="number"
        className="quantity form-control form-control-sm"
        min={1}
        maxLength={4}
        value={manualQuantity}
        disabled={isDoing}
        onChange={(e) =>
          setManualQuantity(
            parseInt((e.target.value || "0").replace(/\D+/gi, ""))
          )
        }
        style={{maxWidth: 70}}
      />
    ) : (
      <select
        id="modalListProQuantity"
        className="form-control form-control-sm"
        disabled={isDoing}
        value={manualQuantity}
        onChange={onChangeQuantity}
        style={{width: "auto"}}
      >
        <TkListQuantityOptions stock={stock}/>
      </select>
    )}
    <button
      type="button"
      disabled={isDoing || manualQuantity < 1}
      onClick={async () => await doUpdate(manualQuantity)}
      className="btn btn-sm btn-destak-purple m-t-20px"
    >
      <TkSvgIcon
        icon={isDoing ? "sync-solid" : "plus-solid"}
        className={`m-r-1em ${isDoing ? "rotate-1-seg" : ""}`}
      />
      {isDoing ? "Adicionando..." : "Adicionar"}
    </button>
  </div>
};

const CardItem: FC<{
  listId?: string;
  productQuantity: ITkProductQuantityModel;
  updateListState(list: List): void;
}> = ({listId, productQuantity, updateListState}) => {
  const {
    product: {
      name,
      thumbnail,
      manufacturerName,
      manufacturerId,
      promoPrice,
      price,
    },
  } = productQuantity;
  let finalPrice = promoPrice || price;

  return <>
    <div className="TkModalList__card">
      <TkProportionalImage url={!!thumbnail ? thumbnail : NoPhoto} plainB={NoPhoto}/>
      <div>
        <h1>{name}</h1>
        <Link
          className="TkModalList__manu-link"
          to={tk_route_manufacturer(manufacturerId)}
          title={`Ver produtos do fabricante ${manufacturerName}`}
        >
          {manufacturerName}
        </Link>
        <div className="d-flex a-i-c j-c-sb m-t-20px">
          <div>
            <div className="TkModalList__unit-price">Preço unitário</div>
            <span className="TkModalList__price">
                {formatMoney(finalPrice)}
              </span>
          </div>
        </div>
      </div>
    </div>
    <TkDivider/>
    <div className="d-flex a-i-c j-c-c m-t-20px">
      <QuantityField
        key={listId ? listId : Math.random()}
        updateListState={updateListState}
        listId={listId}
        productQuantity={productQuantity}
      />
    </div>
  </>
};
const ListLists: FC<{
  lists: List[]
  unFilteredLists: List[]
  onPickUp(list: List): void
  isShowSkeleton: boolean
  isShowLoadMore: boolean
  loadMore(isFirst: boolean): void
  isLoadingMore: boolean
  filter(e: any): void
}> = ({
        lists,
        unFilteredLists,
        onPickUp,
        isShowSkeleton,
        isShowLoadMore,
        loadMore,
        isLoadingMore,
        filter,
      }) => {
  const {setProduct} = useTkListPro();

  const searchContainer = <div className="TkModalList__search_container-bg">
    <label htmlFor="modalListProSearchInput">
      Selecione sua(s) Lista(s) PRO
    </label>
    <section className="TkModalList__search-container">
      <input
        type="search"
        id="modalListProSearchInput"
        disabled={isShowSkeleton || isLoadingMore}
        onChange={filter}
        placeholder="Procurar listas PRO"
      />
      <TkSvgIcon icon="search"/>
    </section>
  </div>;

  if (isShowSkeleton) {
    return <>
      {searchContainer}
      <ul className="TkModalList__list-lists">
        {Array(5)
          .fill(null)
          .map((o, idx) => {
            return (
              <li key={idx}>
                <button className="TkModalList__btn-pickup" type="button">
                    <span className="label ellipsis">
                      <TkSkeleton
                        style={{
                          margin: 0,
                        }}
                      />
                    </span>
                  <span className="counter"/>
                </button>
                <a/>
              </li>
            );
          })}
      </ul>
    </>
  }

  return <>
    {unFilteredLists?.length > 0 && searchContainer}
    <ul className="TkModalList__list-lists">
      {lists.map((l) => {
        return (
          <li key={l._id}>
            <button
              className="TkModalList__btn-pickup"
              onClick={() => onPickUp(l)}
              type="button"
            >
              <span className="label ellipsis">{l.name}</span>
              <span className="counter">
                    {l.items && l.items.length > 0
                      ? `(${l.items.length})`
                      : null}
                  </span>
            </button>
            <Link
              onClick={() => setProduct(null)}
              title={`Ver lista "${l.name}"`}
              to={tk_route_my_list(l._id)}
            >
              <TkSvgIcon icon="share-square"/>
            </Link>
          </li>
        );
      })}

      {!isLoadingMore && isShowLoadMore && (
        <li className="no-grid">
          <button
            type="button"
            onClick={() => loadMore(false)}
            className="TkModalList__btn-load-more"
          >
            Ver mais items
            <TkSvgIcon icon="chevron-down-solid"/>
          </button>
        </li>
      )}

      {isLoadingMore && (
        <li>
          <div className="TkModalList__refresh">
            <TkSvgIcon icon="sync-solid" className="rotate-1-seg"/>
          </div>
        </li>
      )}
    </ul>
  </>
};

const CreateListSheet: FC<{
  isShow: boolean;
  close(): void;
  createList(name: string): Promise<GraphQLResult<List>>;
  updateState(list: List): void;
}> = ({isShow = false, close, createList, updateState}) => {
  const {isMediaXs, isMediaSm} = useTkMedia();
  const [value, setValue] = useState("");
  const [message, setMessage] = useState({
    value: null,
    isError: false,
  });
  const [isCreating, setCreating] = useState(false);
  const [messageTimer, setMessageTimer] = useState(null);

  const createNew = useCallback(async () => {
    try {
      setCreating(true);
      setMessage({
        value: null,
        isError: false,
      });

      if (!isBlank(value)) {
        clearTimeout(messageTimer);
        setMessageTimer(null);
        const {data} = await createList(value);
        updateState(data);

        setValue("");
        setMessage({
          value: "Lista salva com sucesso!",
          isError: false,
        });

        setMessageTimer(
          setTimeout(
            () =>
              setMessage({
                value: null,
                isError: false,
              }),
            3000
          )
        );
      } else {
        setMessage({
          value: "Informe o nome da lista",
          isError: true,
        });
      }
    } catch (e) {
      setMessage({
        value: "Falha na criação da Lista PRO",
        isError: true,
      });
    } finally {
      setCreating(false);
    }
  }, [value, setValue, createList, setMessage, messageTimer, setMessageTimer]);

  useEffect(() => {
    setValue("");
    setMessage({
      value: null,
      isError: false,
    });
  }, [isShow]);

  return <article
    className={`TkModalList__create-list-sheet ${
      isShow ? "TkModalList__create-list-sheet--show" : ""
    } ${
      isMediaSm || isMediaXs ? "TkModalList__create-list-sheet--mobile" : ""
    }`}
  >
    <header>
      <h1>Criar uma nova lista</h1>
      <button type="button" onClick={close}>
        &times;
      </button>
    </header>
    <div className="t-a-c">
      <input
        type="text"
        value={value}
        placeholder="Nova lista"
        onChange={(e) => setValue(e.target.value)}
        className="form-control form-control-sm"
      />
      {message.value && (
        <div
          className={`alert alert-sm alert-${
            message.isError ? "danger" : "success"
          } m-t-10px`}
        >
          {message.value}
        </div>
      )}
      <button
        type="button"
        onClick={createNew}
        disabled={isCreating}
        className="btn btn-sm btn-destak-purple m-t-20px"
      >
        <TkSvgIcon
          icon={isCreating ? "sync-solid" : "plus-solid"}
          className={`m-r-1em ${isCreating ? "rotate-1-seg" : ""}`}
        />
        {isCreating ? "Criando..." : "Criar"}
      </button>
    </div>
  </article>
};

const AddProductToListSheet: FC<{
  list?: List;
  product?: ITkProductModel;
  close(): void;
  updateListState(list: List): void;
}> = ({list, product, close, updateListState}) => {
  const {isMediaXs, isMediaSm} = useTkMedia();
  return <article
    className={`TkModalList__add-product-sheet ${
      list ? "TkModalList__add-product-sheet--show" : ""
    } ${
      isMediaSm || isMediaXs ? "TkModalList__add-product-sheet--mobile" : ""
    }`}
  >
    <header>
      <h1>{list && list.name}</h1>
      <Link to={list ? tk_route_my_list(list._id) : ""} onClick={close}>
        <TkSvgIcon icon="share-square"/>
      </Link>
    </header>
    <CardItem
      updateListState={updateListState}
      listId={list ? list._id : null}
      productQuantity={{product, quantity: 1}}
    />
  </article>
};

const AddNewList: FC<{
  updateState(list: List): void;
}> = ({ updateState }) => {
  const { goToPageError } = useTkAppErrors();
  const { createList } = useTkUser();
  const [isSaving, setSaving] = useState(false);
  const [message, setMessage] = useState({
    type: "success",
    message: null,
  });
  const {
    bind: bindNameList,
    value,
    setValue: setNameList,
  } = useSimpleInput("");

  const doSave = useCallback(async () => {
    setMessage({
      type: "success",
      message: null,
    });

    if (isBlank(value)) {
      setMessage({
        type: "danger",
        message: "Preencha o nome da lista",
      });
    } else {
      try {
        setSaving(true);
        const result = await createList(value);

        setMessage({
          type: "success",
          message: "Lista salva com sucesso",
        });

        const t = setTimeout(() => {
          setSaving(false);
          setNameList("");

          setMessage({
            type: "success",
            message: null,
          });
          updateState(result.data);
        }, 2000);

        return () => clearTimeout(t);
      } catch (e) {
        goToPageError(e);
      }
    }
  }, [
    setSaving,
    createList,
    setNameList,
    goToPageError,
    value,
    setMessage,
    updateState,
  ]);

  return (
    <div className="TkModalList__add-new-container">
      <b>Criar uma nova lista</b>
      <input
        type="text"
        required
        className="form-control form-control-sm"
        {...bindNameList}
      />
      {message.message && (
        <div className="m-t-20px">
          <div className={`alert alert-sm alert-${message.type}`}>
            {message.message}
          </div>
        </div>
      )}

      <div className="t-a-c m-t-20px">
        <button
          type="button"
          disabled={isSaving}
          onClick={doSave}
          className="btn btn-sm btn-destak-purple"
        >
          <TkSvgIcon
            icon={isSaving ? "sync-solid" : "plus-solid"}
            className={`m-r-1em ${isSaving ? "rotate-1-seg" : ""}`}
          />
          {isSaving ? "Criando..." : "Criar"}
        </button>
      </div>
    </div>
  );
};

const TkModalList: ForwardRefRenderFunction<ITkModalList> = ({}, ref) => {
  const {getLists, createList} = useTkUser();
  const {isMediaXs, isMediaSm} = useTkMedia();
  const {setShowIcon} = useTkWhatsAppBalloon()
  const {setProduct} = useTkListPro();
  const [selectedProduct, setSelectedProduct] = useState<ITkProductModel>(null);
  const {goToPageError} = useTkAppErrors();
  const [isOpened, setOpened] = useState(false);
  const [myLists, setMyLists] = useState<List[]>([]);
  const [filteredLists, setFilteredLists] = useState<List[]>([]);
  const [selectedList, setSelectedList] = useState<List>(null);
  const [isLoadingLists, setLoadingLists] = useState(false);
  const [isHitLastList, setHitLastList] = useState(false);
  const [isShowCreateNew, setShowCreateNew] = useState(false);
  const [isShowAdded, setShowAdded] = useState(false);
  const NUM_LISTS = 10;

  const loadMore = useCallback(async (isFirst = true) => {
      try {
        setLoadingLists(true);
        const lastList: List = myLists?.length > 0 ? myLists[myLists.length - 1] : null;
        const start = lastList ? lastList.timeMillis : 0;
        const result = await getLists(
          lastList ? lastList._id : null,
          start,
          NUM_LISTS
        );

        if (result?.data?.length > 0) {
          if (isFirst) {
            setMyLists(result.data);
            setFilteredLists([...result.data]);
          } else {
            setMyLists([...myLists, ...result.data]);
            setFilteredLists([...filteredLists, ...result.data]);
          }
        }
        setHitLastList(result?.data?.length === 0);
      } catch (e) {
        goToPageError(e);
      } finally {
        setLoadingLists(false);
      }
    }, [filteredLists, myLists, setFilteredLists, setHitLastList, setMyLists, getLists, goToPageError, selectedProduct, setLoadingLists,]
  );

  useEffect(() => {
    document.body.classList[isOpened ? "add" : "remove"]("o-h");
    setShowIcon(!isOpened)
    if (!selectedProduct) {
      setMyLists([]);
      setFilteredLists([]);
    }
  }, [isOpened, selectedProduct]);

  const filter = useCallback((e) => {
      if (myLists) {
        const term = e.target.value;
        if (isBlank(term)) setFilteredLists([...myLists]);
        else
          setFilteredLists(
            myLists.filter((l) =>
              replaceDiacritics(l.name)
                .toLowerCase()
                .indexOf(replaceDiacritics(term).toLowerCase()) > -1
            )
          );
      }
    },
    [myLists, setFilteredLists]
  );

  const hide = useCallback(() => {
    setOpened(false);
    setSelectedProduct(null);
    setSelectedList(null);
    setProduct(null);
    setShowAdded(false);
  }, [
    setOpened,
    setSelectedProduct,
    setSelectedList,
    setProduct,
    setShowAdded,
  ]);

  useImperativeHandle(ref, () => {
    return {
      async show(product: ITkProductModel) {
        setOpened(true);
        setSelectedProduct(product);
        await loadMore();
      },
      hide,
      isShow: isOpened
    };
  });

  if (!selectedProduct) return <></>;

  return <>
    <div
      onClick={hide}
      className={`TkModalList__overlay ${
        isOpened ? "TkModalList__overlay--show" : ""
      }`}
    />

    {isShowAdded && (
      <div className="TkModalList__added-container">
        <div className="TkModalList__alert-added">Adicionado</div>
      </div>
    )}
    <article
      className={`TkModalList ${
        isMediaXs || isMediaSm ? "TkModalList--mobile" : ""
      }`}
    >
      <section className="TkModalList__header">
        <TkSvgIcon icon="list-alt-regular"/>
        <div className="TkModalList__title-line">
          <h1>
            Teky PRO <b>Minhas listas</b>
          </h1>
          <p>
            Gerenciar{" "}
            <Link onClick={hide} to={tk_route_my_lists}>
              minhas listas
            </Link>
          </p>
        </div>
        <button type="button" onClick={hide}>
          <TkSvgIcon icon="close"/>
        </button>
      </section>

      {(isLoadingLists || myLists?.length > 0) && <ListLists
          lists={filteredLists}
          unFilteredLists={myLists}
          onPickUp={setSelectedList}
          isShowSkeleton={isLoadingLists && myLists.length === 0}
          isShowLoadMore={!isHitLastList}
          loadMore={loadMore}
          isLoadingMore={isLoadingLists && myLists.length > 0}
          filter={filter}
      />}

      {!isLoadingLists && myLists?.length === 0 && <AddNewList updateState={(list) => {
        setMyLists([list, ...myLists]);
        setFilteredLists([list, ...filteredLists]);
      }}/>}

      {!!filteredLists && filteredLists.length > 0 && (
        <button
          type="button"
          onClick={() => setShowCreateNew(true)}
          className="TkModalList__btn-create"
        >
          <TkSvgIcon icon="plus-solid"/>
          <span className="label">Criar nova Lista PRO</span>
          <TkSvgIcon icon="chevron-right-solid"/>
        </button>
      )}
    </article>
    {(isShowCreateNew || selectedList) && (
      <div
        onClick={() => {
          setShowCreateNew(false);
          setSelectedList(null);
        }}
        className={`TkModalList__inside-overlay ${
          isMediaXs || isMediaSm ? "TkModalList__inside-overlay--mobile" : ""
        }`}
      />
    )}
    <CreateListSheet
      isShow={isShowCreateNew}
      createList={createList}
      updateState={(list) => {
        setMyLists([list, ...myLists]);
        setFilteredLists([list, ...filteredLists]);
      }}
      close={() => setShowCreateNew(false)}
    />

    <AddProductToListSheet
      list={selectedList}
      product={selectedProduct}
      updateListState={(list) => {
        setMyLists([list, ...myLists.filter((l) => l._id !== list._id)]);
        setFilteredLists([
          list,
          ...filteredLists.filter((l) => l._id !== list._id),
        ]);
        setSelectedList(null);
        setShowAdded(true);
        setTimeout(() => {
          setShowAdded(false);
          hide();
        }, 2000);
      }}
      close={hide}
    />
  </>
};

export default forwardRef(TkModalList);
