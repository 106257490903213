import React, {memo} from "react";
import LogoTeky from "../../../assets/images/logo-teky-02.jpg";
import logoTeky from "../../../assets/images/logo-teky-02.jpg";
import {isBlank} from "../../../utils/string-utils";
import {Helmet} from "react-helmet";
import {ITkAttributeModel, ITkFamilyModel, ITkProductModel} from "../../../models/product";
import {tk_route_product_detail} from "../../../views/TkProductDetailView";
import {tk_var_email, tk_var_location, tk_var_phone1, tk_var_teky_name} from "../../../views/TkBeInTouchView";
import {useTkBreadcrumb} from "../../../context/TkContext";
import {Breadcrumb} from "../../../context/TkBreadCrumbContext";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-image.svg';

const tekyDomain = process.env.REACT_APP_HOSTNAME;

const getBreadcrumb = (breadcrumb: Breadcrumb[] | null) => {
    if (!breadcrumb || breadcrumb.length === 0) return null;

    return {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumb.map((b, index) => {
            return {
                "@type": "ListItem",
                "position": index + 1,
                "item": {
                    "@id": `${tekyDomain}${b.url}`,
                    "name": b.label
                }
            }
        })
    }
}

const getWebPageSchema = (url: string, title: string, description: string, keywords: string, imageUrl: string, breadcrumb?: Breadcrumb[] | null) => {
    const location = tk_var_location();
    const now = new Date();

    let schema: any = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": title,
        "description": description,
        "alternativeHeadline": keywords,
        "primaryImageOfPage": imageUrl,
        "url": url,
        "inLanguage": "pt-BR",
        "isFamilyFriendly": "true",
        "keywords": keywords,
        "potentialAction": {
            "@type": "SearchAction",
            "target": `${tekyDomain}/pesquisar?term={search_term_string}`,
            "query-input": "required name=search_term_string"
        },
        "author": {
            "@context": "http://schema.org",
            "@type": "Organization",
            "address": {
                "@type": "PostalAddress",
                "addressRegion": location.addressRegion,
                "addressLocality": location.addressLocality,
                "addressCountry": location.addressCountry,
                "postalCode": location.postalCode,
                "streetAddress": `${location.streetAddress}, ${location.streetAddressNumber}`
            },
            "logo": `${tekyDomain}${logoTeky}`,
            "email": tk_var_email,
            "name": "Teky",
            "telephone": tk_var_phone1(true)
        },
        "copyrightYear": now.getFullYear(),
        "datePublished": "2020-04-16"
    };

    if (breadcrumb && breadcrumb.length > 0) {
        schema = {
            ...schema,
            "breadcrumb": getBreadcrumb(breadcrumb)
        }
    }

    return schema
};

const getProductSchema = (url: string, description: string, product: ITkProductModel, keywords: string, imageUrl: string, breadcrumb?: Breadcrumb[] | null) => {
    const now = new Date();

    const family: ITkFamilyModel = product?.hierarchies?.length > 0 ? product?.hierarchies[0].family : null
    const availabilityEnum = {
        InStock: "http://schema.org/InStock",
        OutOfStock: "http://schema.org/OutOfStock",
        BackOrder: "http://schema.org/BackOrder",
        Discontinued: "http://schema.org/Discontinued"
    }

    let price = product.promoPrice || product.price;
    let productAvailability = availabilityEnum.OutOfStock

    if (product.isActive) {
        if (product.price > 0) {
            if(product.stock > 0) productAvailability = availabilityEnum.InStock
            if(product.stock == 0 && product.leadTime > 0) productAvailability = availabilityEnum.BackOrder
        }
    } else {
        productAvailability = availabilityEnum.Discontinued
    }

    let schema: any = {
        "@context": "http://schema.org",
        "@type": "Product",
        "description": description,
        "url": url,
        "name": product.name.trim(),
        "image": imageUrl,
        "category": family?.name,
        "productID": product._id,
        "sku": product._id,
        "gtin": product.ean,
        "brand": {
            "@type": "Brand",
            "name": product.manufacturerName
        },
        "offers": {
            "@type": "Offer",
            "priceCurrency": "BRL",
            "price": price,
            "url": url,
            "availability": productAvailability,
            "itemCondition": "http://schema.org/NewCondition",
            "priceValidUntil": `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
            "seller": {
                "@type": "Organization",
                "name": tk_var_teky_name
            }
        }
    }

    if (!isBlank(product.generalInfo)) {
        schema = {
            ...schema,
            "description": product.generalInfo
        }
    }

    if (product?.attributes) {
        schema = {
            ...schema,
            "additionalProperty": product.attributes.map((attr: ITkAttributeModel) => {
                return {
                    "@type": "PropertyValue",
                    "name": attr.name.trim(),
                    "value": attr.value.trim()
                }
            })
        }
    }

    if (product?.extraInfo) {
        const properties: any = {}

        for (const key of ['weight', 'height', 'width', 'depth']) {
            if (product?.extraInfo[key as keyof typeof product.extraInfo]) {
                properties[key] = product.extraInfo[key as keyof typeof product.extraInfo]
            }
        }

        if (Object.keys(properties).length > 0) {
            schema = {
                ...schema,
                ...properties
            }
        }
    }

    return schema
};

const TkHeadSEO: React.FC<{
    title: string,
    description?: string,
    keywords?: string,
    image?: string,
    url?: string,
    product?: ITkProductModel
}> = ({
          title = 'Teky',
          description = 'Comércio de Ferramentas, EPIs e EPCs, Materiais Elétricos, Motores elétricos, Materias Hidráulicos, Abrasivos, Rolamentos',
          keywords = 'Ferramentas, EPIs e EPCs, Furadeiras, Motores elétricos, Materias Hidráulicos, Abrasivos, Rolamentos',
          image,
          url,
          product
      }) => {

    const {breadcrumb} = useTkBreadcrumb();
    let imageUrl = image ? image : `${tekyDomain}${LogoTeky}`;

    if (isBlank(url)) url = document.location.href;

    let productSchema;

    let productAvailability = 'out of stock';

    let webpageSchema;

    if (product) {

        if (product.isActive) {
            if (product.stock > 0 && product.price > 0) productAvailability =  'in stock'
        } else productAvailability = 'discontinued'

        const name = product.name;
        title = `${name} | Teky`;

        if (isBlank(product.generalInfo)) {
            description = product.attributes ? product.attributes.reduce((obj: Array<any>, attr: ITkAttributeModel) => {
                obj.push(`${attr.name.trim()}: ${attr.value.trim()}`);
                return obj;
            }, []).join(', ') : name;
        } else {
            description = product.generalInfo.trim()
        }

        keywords = name.replace(/\s\s+/g, ' ').split(' ').join(', ');
        imageUrl = !isBlank(product.thumbnail) ? product.thumbnail : NoPhoto;

        url = `${tekyDomain}${tk_route_product_detail(product)}`;

        const now = new Date();
        now.setDate(now.getDate() + 3);

        webpageSchema = getWebPageSchema(url, product.name, description, keywords, imageUrl, breadcrumb)
        productSchema = getProductSchema(url, description, product, keywords, imageUrl, breadcrumb);
    } else {
        webpageSchema = getWebPageSchema(url, title, description, keywords, imageUrl, breadcrumb)
    }

    return <Helmet>
        <title>{title}</title>
        
        {product && !product.isActive ? <meta name="robots" content="noindex, follow" /> : null}

        <meta name="description" content={description}/>
        <meta name="keywords" content={keywords}/>

        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={imageUrl}/>
        <meta property="og:url" content={url}/>
        <meta property="og:site_name" content="Teky"/>
        <meta property="og:type" content={product ? 'product' : 'page'}/>
        <meta property="og:locale" content="pt_BR"/>
        <meta property="og:image:alt" content={title}/>

        <meta name="twitter:url" content={url}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={description}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:image" content={imageUrl}/>
        <meta name="twitter:image:alt" content={title}/>

        {product ? [
            <meta property="product:price:amount" content={`${product.promoPrice || product.price}`} />,
            <meta property="product:price:currency" content="BRL" />,
            <meta property="product:availability" content={productAvailability} />,
            <meta property="product:condition" content="new" />,
            <meta property="product:brand" content={product.manufacturerName} />,
            <meta property="product:retailer_item_id" content={product._id} />,
            <meta name="twitter:data1" content={`${product.promoPrice || product.price} BRL`}/>,
            <meta name="twitter:label1" content="Price"/>,
            <meta name="twitter:data2" content={productAvailability}/>,
            <meta name="twitter:label2" content="Availability"/>,
        ].map(meta => meta) : null}

        <link rel="canonical" href={product ? `${tekyDomain}${tk_route_product_detail(product)}` : document.location.href}/>;

        <script type='application/ld+json'>{JSON.stringify(webpageSchema)}</script>
        {product ? <script type='application/ld+json'>{JSON.stringify(productSchema)}</script> : null}
    </Helmet>
};


export default memo(TkHeadSEO)
